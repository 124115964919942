<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-flex uk-flex-center">
            <div class="uk-margin-right uk-width-auto">
                <div class="uk-card-small uk-card-default uk-card-body uk-width-expand uk-margin-auto uk-border-rounded uk-text-center">
                    <div class="uk-width-1-1 uk-text-center">
                        <h3>Jumlah User</h3>
                    </div>
                    <div uk-spinner class="uk-width-1-1 uk-flex uk-flex-center" v-show="data == null || loadingTotalUser"></div>
                    <div class="uk-width-1-1 uk-flex uk-flex-center" v-if="data != null && !loadingTotalUser">
                        <div class="uk-card-small uk-card-default uk-card-body uk-border-rounded uk-margin-small-right uk-text-center uk-width-auto">
                            <div class="uk-width-1-1">
                                <h4>Candidate</h4>
                            </div>
                            <div class="uk-width-1-1">
                                <h1>{{formatCurrency(data.total_candidate)}}</h1>
                            </div>
                            <div class="uk-width-1-1">
                                <h4>User</h4>
                            </div>
                        </div>
                        <div class="uk-card-small uk-card-default uk-card-body uk-border-rounded uk-margin-small-right uk-text-center uk-width-auto">
                            <div class="uk-width-1-1">
                                <h4>Employer</h4>
                            </div>
                            <div class="uk-width-1-1">
                                <h1>{{formatCurrency(data.total_employer)}}</h1>
                            </div>
                            <div class="uk-width-1-1">
                                <h4>User</h4>
                            </div>
                        </div>
                        <div class="uk-card-small uk-card-default uk-card-body uk-border-rounded uk-text-center uk-width-auto">
                            <div class="uk-width-1-1">
                                <h4>Mitra</h4>
                            </div>
                            <div class="uk-width-1-1">
                                <h1>{{formatCurrency(data.total_mitra)}}</h1>
                            </div>
                            <div class="uk-width-1-1">
                                <h4>User</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="uk-width-auto">
                <div class="uk-card-small uk-card-default uk-card-body uk-width-expand uk-margin-auto uk-border-rounded uk-text-center">
                    <div class="uk-width-1-1 uk-text-center">
                        <h3>Jumlah User PWA Baru</h3>
                    </div>
                    <div uk-spinner class="uk-width-1-1 uk-flex uk-flex-center" v-show="data.newPWA_data.total_candidate_newPWA == null || loadingTotalNewUser"></div>
                    <div class="uk-width-1-1 uk-flex uk-flex-center" v-if="data.newPWA_data.total_candidate_newPWA != null && !loadingTotalNewUser">
                        <div class="uk-card-small uk-card-default uk-card-body uk-border-rounded uk-margin-small-right uk-text-center uk-width-auto">
                            <div class="uk-width-1-1">
                                <h4>Candidate</h4>
                            </div>
                            <div class="uk-width-1-1">
                                <h1>{{formatCurrency(data.newPWA_data.total_candidate_newPWA)}}</h1>
                            </div>
                            <div class="uk-width-1-1">
                                <h4>User</h4>
                            </div>
                        </div>
                        <div class="uk-card-small uk-card-default uk-card-body uk-border-rounded uk-text-center uk-width-auto">
                            <div class="uk-width-1-1">
                                <h4>Mitra</h4>
                            </div>
                            <div class="uk-width-1-1">
                                <h1>{{formatCurrency(data.newPWA_data.total_mitra_newPWA)}}</h1>
                            </div>
                            <div class="uk-width-1-1">
                                <h4>User</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="uk-card uk-card-default uk-card-body uk-margin-auto uk-margin-top uk-border-rounded">
            <div class="uk-flex" uk-grid>
                <div class="uk-width-1-1 uk-text-left">
                    <h4>Data Komparasi Pertumbuhan User</h4>
                </div>
                <div class="uk-width-auto uk-text-center uk-margin-remove-top">
                    <datetime
                        input-class="uk-button"
                        type="date"
                        format="MMMM yyyy"
                        :flow="['year', 'month']"
                        name="date_new_candidate"
                        :max-datetime="today_date"
                        v-model="date_new_candidate"
                        placeholder="Date"
                    ></datetime>
                </div>
                <div class="uk-width-1-1 uk-margin-medium-bottom" v-show="chart_new_candidate == null || loadingChartNewCandidate">
                    <div uk-spinner class="uk-position-center uk-margin-medium-top"></div>
                </div>
                <div class="uk-width-1-1" v-if="chart_new_candidate != null && !loadingChartNewCandidate">
                    <bar-chart :chartdata="chart_new_candidate" :options="chart_options" />
                </div>
            </div>
        </div>
        <div class="uk-card uk-card-default uk-card-body uk-margin-auto uk-margin-top uk-border-rounded" style="min-width:390px">
                <div class="uk-flex" uk-grid>
                    <div class="uk-width-1-1 uk-text-left">
                        <h4>Pertumbuhan User Baru (New PWA) - 7 Days ago</h4>
                    </div>
                    <div class="uk-width-auto uk-text-center uk-margin-remove-top">
                        <datetime
                            input-class="uk-button"
                            type="date"
                            format="EEEE, dd MMMM yyyy"
                            name="date_new_candidate_newPWA"
                            v-model="date_new_candidate_newPWA"
                            :max-datetime="today_date"
                            placeholder="Date"
                        ></datetime>
                    </div>
                    <div class="uk-width-1-1 uk-margin-medium-bottom" v-show="chart_pwa_candidate == null || loadingCandidatePWA">
                        <div uk-spinner class="uk-position-center uk-margin-medium-top"></div>
                    </div>
                    <div class="uk-width-1-1" v-if="chart_pwa_candidate != null && !loadingCandidatePWA">
                        <line-chart :chartdata="chart_pwa_candidate" :options="chart_options" />
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import BarChart from '@/components/globals/chart/BarChart';
import LineChart from '@/components/globals/chart/LineChart';
import moment from 'moment';
import formatter from "@/utils/formatter";
import { Datetime } from 'vue-datetime';

moment.locale('id');

export default {
    data() {
        return {
            data: {
                total_candidate: '-',
                total_employer: '-',
                total_mitra: '-',
                newPWA_data: {
                    total_candidate_newPWA: '-',
                    total_mitra_newPWA: '-'
                }
            },
            today_date: null,
            date_new_candidate: null,
            date_new_candidate_newPWA: null,
            candidate_data: null,
            pwa_candidate: null,
            loadingTotalUser: false,
            loadingTotalNewUser: false,
            loadingChartNewCandidate: false,
            loadingCandidatePWA: false,
            chart_new_candidate: null,
            chart_pwa_candidate: null,
            chart_options: {
                responsive: true,
                maintainAspectRatio: false
            },
        };
    },
    created() {
        this.today_date = moment().toISOString();
        this.date_new_candidate = this.today_date;
        this.date_new_candidate_newPWA = this.today_date;
        Promise.all([
            this.setTotalNewUserData(),
            this.setTotalUserData()
        ]);
    },
    components: {
        BarChart,
        LineChart,
        Datetime
    },
    watch: {
        date_new_candidate() {
            this.getNewCandidateData();
        },
        date_new_candidate_newPWA() {
            this.getNewCandidateNewPWAData();
        }
    },
    methods: {
        ...mapActions({
            getTotalUser: 'dashboard/getTotalUser',
            getTotalNewUser: 'dashboard/getTotalNewUser',
            getNewCandidate: 'dashboard/getNewCandidate',
            getCandidateOnNewPWA: 'dashboard/getCandidateOnNewPWA',
        }),
        async setTotalUserData(){
            this.loadingTotalUser = true;
            const dataUser = await this.getTotalUser();
            this.data = {...this.data, ...dataUser};
            this.loadingTotalUser = false;
        },
        async setTotalNewUserData(){
            this.loadingTotalNewUser = true;
            const dataNewUser = await this.getTotalNewUser();
            this.data.newPWA_data = dataNewUser.newPWA_data;
            this.loadingTotalNewUser = false;
        },
        async getNewCandidateData() {
            this.loadingChartNewCandidate = true;
            this.candidate_data = await this.getNewCandidate({date: this.date_new_candidate});
            this.chart_new_candidate = this.candidate_data.chartData;
            this.loadingChartNewCandidate = false;
        },
        async getNewCandidateNewPWAData() {
            this.loadingCandidatePWA = true;
            this.pwa_candidate = await this.getCandidateOnNewPWA({date: this.date_new_candidate_newPWA});
            this.chart_pwa_candidate = {
                labels: this.pwa_candidate.chartData.labels,
                datasets: [
                    {
                        label: 'User Baru',
                        fill: false,
                        data: this.pwa_candidate.chartData.datasets_candidate,
                        tension: 0,
                        borderColor: '#0ABAB5'
                    },
                    {
                        label: 'Mitra Baru',
                        fill: false,
                        data: this.pwa_candidate.chartData.datasets_mitra,
                        tension: 0,
                        borderColor: '#febe10'
                    }
                ]
            };
            this.loadingCandidatePWA = false;
        },
        amountLabel(amount){
            if (amount==0) {
                return '-';
            } else {
                return amount+ ' User';
            }
        },
        formatCurrency(data) {
            if (data == null) {
                return '-';
            }
            return formatter.thousandSeparator(data);
        },
    },
};
</script>
